<template>
    <div class="hotline-info" v-bind:class="{isOpen: helpopen}" v-bind:style="{backgroundColor: panelBackgroundDark, color: labelColorLight}">
        <button type="button" class="close" aria-label="Close" v-on:click="toggleOpen" v-if="helpopen"><span aria-hidden="true">&times;</span></button>
        <div class="text-center hotline-toggle"><a href="https://www.ideal-versicherung.de/impressum" target="_blank" v-bind:style="{color:labelColorLight}">Impressum</a> | <a href="https://www.ideal-versicherung.de/datenschutz" target="_blank"  v-bind:style="{color:labelColorLight}">Datenschutz</a> | <span v-on:click="toggleOpen">Hilfe & Kontakt</span></div>
        <div class="hotline-body col-lg-12 mt-3">
            <div class="col-lg-6">
                <strong>Kundenservice</strong><br/>
                <a href="tel:+49302587259" v-bind:style="{color:labelColorLight}">030 / 25 87 - 259 (Mo-Fr von 8-18 Uhr)</a>
            </div>
            <div class="col-lg-6 mt-2 mb-3">
                <strong>Vertriebspartner-Hotline</strong><br/>
                <a href="tel:+49302587261" v-bind:style="{color:labelColorLight}">030 / 2587 261 (Mo-Fr von 9-18 Uhr)</a>
            </div>
        </div>
    </div>
</template>
<script>
    import {theme} from '../mixin/theme.js'
    import { mapGetters } from 'vuex'

    export default {
        computed: {
            ...mapGetters({ helpopen: 'helpopen'})
        },
        mixins: [theme],
        methods: {
            toggleOpen: function() {
                this.$store.dispatch("toggleHelpOpen")
            }
        }
    }

</script>
<style scoped="true" lang="scss">
    .close {
        position: absolute;
        top:5px;
        right:15px;
        font-size:20px
    }
    .hotline-toggle  {
        cursor: pointer;
        font-size: 0.8em;
        padding-top:0.3em;
    }
    .hotline-info {
        position: fixed;
        bottom:0;
        width:100%;
        height:25px;
        z-index: 1001;
        &.isOpen {
            height:auto;
        }

        -webkit-box-shadow: 0px -1px 17px -1px rgba(0,0,0,0.50);
        -moz-box-shadow: 0px -1px 17px -1px rgba(0,0,0,0.50);
        box-shadow: 0px -1px 17px -1px rgba(0,0,0,0.50);
    }
    .hotline-body {
        font-size:0.9em;
    }
</style>