
import store from '../state/index.js'

export var theme = {
  data: function () {
    return {
      bodyColor: store.getters['theme/bodyColor'],
      panelBackgroundLight: store.getters['theme/panelBackgroundLight'],
      panelBackgroundDark: store.getters['theme/panelBackgroundDark'],
      primaryCTAColor: store.getters['theme/primaryCTAColor'],
      secondaryCTAColor: store.getters['theme/secondaryCTAColor'],
      panelBackgroundNeutral: store.getters['theme/panelBackgroundNeutral'],
      labelColorLight: store.getters['theme/labelColorLight'],
      labelColorDark: store.getters['theme/labelColorDark'],
      inputStyles: store.getters['theme/inputStyles'],
      positivColor: store.getters['theme/positivColor'],
      pendingColor: store.getters['theme/pendingColor'],
      menuBackground: {'backgroundColor': store.getters['theme/panelBackgroundDark']}
    }
  }
}
