export const theme = {
  namespaced: true,
  state: {
    bodyColor: '#4B96C8',
    panelBackgroundNeutral: '#fff',
    panelBackgroundLight: '#F4F4F4',
    panelBackgroundDark: '#4678AA',
    primaryCTAColor: {
      backgroundColor: '#F59B69',
      borderColor: '#F59B69',
      borderRadius: '0'
    },
    secondaryCTAColor: {
      backgroundColor: '#4678AA',
      borderColor: '#4678AA',
      borderRadius: '0',
      color: '#fff'
    },
    labelColorLight: '#fff',
    labelColorDark: '#003273',
    inputStyles: {
      borderRadius: '0'
    },
    positivColor: '#60ad5e',
    pendingColor: '#F59B69'
  },
  getters: {
    bodyColor: (state) => {
      return state.bodyColor
    },
    panelBackgroundNeutral: (state) => {
      return state.panelBackgroundNeutral
    },
    panelBackgroundLight: (state) => {
      return state.panelBackgroundLight
    },
    panelBackgroundDark: (state) => {
      return state.panelBackgroundDark
    },
    primaryCTAColor: (state) => {
      return state.primaryCTAColor
    },
    secondaryCTAColor: (state) => {
      return state.secondaryCTAColor
    },
    labelColorLight: (state) => {
      return state.labelColorLight
    },
    labelColorDark: (state) => {
      return state.labelColorDark
    },
    inputStyles: (state) => {
      return state.inputStyles
    },
    positivColor: (state) => {
      return state.positivColor
    },
    pendingColor: (state) => {
      return state.pendingColor
    }
  }
}
