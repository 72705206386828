<template>
    <div class="global-error">
        <button type="button" class="close" aria-label="Close" v-on:click="close()">
            <span aria-hidden="true">&times;</span>
        </button>
        {{message}}
        <div class="col-lg-12 text-center mt-2" v-if="showlogin">
            <router-link tag="button" to="/login" class="btn btn-primary" v-if="!authentificated" v-bind:style="[primaryCTAColor]">Anmelden</router-link>
        </div>

    </div>
</template>

<script>
import { mapGetters } from 'vuex'
import { theme } from '../mixin/theme.js'
import { errorsForHumans } from '../constants'

export default {
  mixins: [theme],
  computed: {
    ...mapGetters(['authentificated']),
    message: function () {
      let msg = this.$store.getters['message']
      // Anwender ist nicht mehr angemeldet.
      if (msg.data.error === 'invalid_grant') {
        return 'Anmeldung nicht möglich. Benutzername oder Passwort falsch.'
      }
      switch (msg.status) {
        case 400:
          if (this.errorsForHumans[msg.data.error_description]) {
            return this.errorsForHumans[msg.data.error_description]
          }
          break
        case 401:
          this.showLogin(true)
          this.$store.dispatch('setAuthentificated', false)
          return 'Ihre Sitzung ist abgelaufen. Bitte erneut anmelden'
        case 405:
          return 'Unbekannter Login. Ist eventuell die Vertriebspartnernummer fehlerhaft? '
        case 415:
          return 'Der Server hat die Anfrage abgelehnt. Es wurde ein ungültiges Format übermittelt'
        //case 500:
         // return 'Beim Abruf des Dokumentes ist es zu einem Fehler gekommen. Vermutlich haben Sie keine Berechtigung.'
        default: {
          //
        }
      }

      return this.$store.getters['message']
    }
  },
  data: function () {
    return {
      errorsForHumans: {}
    }
  },
  methods: {
    close: function () {
      this.$store.dispatch('setError', {
        state: false,
        message: {},
        showlogin: false
      })
    },
    showLogin: function (toggle) {
      this.showlogin = toggle
    }
  },
  mounted: function () {
    this.errorsForHumans = errorsForHumans

    setTimeout(() => this.close(), 45000)
  }
}
</script>
