<template>
    <nav class="navbar navbar-expand-lg navbar-light bg-light">
        <a class="navbar-brand" href="#"><img src="@/assets/logo.gif"></a>
        <button class="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarNavAltMarkup" aria-controls="navbarNavAltMarkup" aria-expanded="false" aria-label="Toggle navigation" v-on:click="show=!show" v-if="authentificated">
            <span class="navbar-toggler-icon"></span>
        </button>
        <div class="collapse navbar-collapse" id="navbarNavAltMarkup" v-bind:class="{ show: show }" v-if="authentificated" v-bind:style="[show ? menuBackground: '']">
            <div class="navbar-nav">
                <router-link tag="a" to="/vorgaenge" class="nav-item nav-link" href="#" v-bind:style="[show ? {color:labelColorLight}: {color:labelColorDark}]">Vorgangsübersicht</router-link>
                <router-link tag="a" :to="{name: 'dokument', params: {id: vorgang.id}}" class="nav-item nav-link" href="#" v-if="vorgang"  v-bind:style="[show ? {color:labelColorLight}: {color:labelColorDark}]">{{vorgang.dokumente[0].name}}</router-link>
                <a to="/unterschriften" class="nav-item nav-link" href="#" v-on:click="logout()" v-bind:style="[show ? {color:labelColorLight}: {color:labelColorDark}]">Abmelden</a>
            </div>
        </div>
    </nav>
</template>
<script>

    import { mapGetters } from 'vuex'
    import {theme} from '../mixin/theme.js'

    export default {
        mixins: [theme],
        data: function() {
            return  {
                show: false,
                activeMenuEntry: false
            }
        },
        computed: {
            ...mapGetters(['authentificated', 'vorgang'])
        },
        watch:{
            $route (to, from){
               this.show = false
            }
        },
        methods: {
            logout: function() {
                this.$store.dispatch('logout')
                this.$router.push('/login');
            }
        }
    }
</script>
<style scoped="true" lang="scss">
   .navbar-brand img {
       max-height:40px;
   }
   .router-link-active {
        font-weight:bold;
   }

   #navbarNavAltMarkup.show {
       position: absolute;
       width: 100%;
       padding: 0.5em 1em;
       margin: 0;
       top: 65px;
       z-index: 2000;
       left: 0;
   }
</style>