<template>
    <div class="container-fluid">
        <div class="panel panel-default panel-login" v-bind:style="{'background-color': panelBackgroundNeutral}">
            <div class="panel-heading"></div>
            <div class="panel-body">
                <div class="login-mask">
                    <h2 class="mb-4">Anmeldung</h2>
                    <form class="">
                        <div class="form-group">
                            <input type="email" class="form-control" id="exampleInputEmail1" aria-describedby="emailHelp" placeholder="VPA-Nr." v-model="username" v-bind:style="[inputStyles]" @keyup.enter="login">
                        </div>
                        <div class="form-group">
                            <input type="password" class="form-control" id="exampleInputPassword1" placeholder="Passwort" v-model="password" v-bind:style="[inputStyles]" @keyup.enter="login">
                        </div>
                    </form>
                    <div v-if="error" class="alert alert-warning">{{message}}</div>
                    <button class="btn btn-primary btnfloat-right" v-on:click="login" v-bind:style="[primaryCTAColor]">Anmelden</button>
                    <div class="clearfix d-md-none"></div>
                   <!-- <a href="" class="d-block text-center mt-3"><small>Passwort zurücksetzen</small></a>-->
                </div>
            </div>
        </div>
    </div>
</template>
<script>
    import {theme} from '../mixin/theme.js'

    export default {
        mixins: [theme],
        data: function() {
            return {
                username : null,
                password: null,
                error: false,
            }
        },
        methods: {
            login: async function() {

                if (this.username == null || this.password == null ) {
                    this.error = true
                    this.message = "Bitte geben Sie einen Anmeldenamen und ein Passwort ein"
                } else {
                    this.error = false
                    try {
                      await this.$store.dispatch('login',{username: this.username, password: this.password})
                      let next = (typeof this.$route.query.to === "undefined") ? "vorgaenge": this.$route.query.to
                      this.$router.push(next);
                    } catch(e) {
                        this.error = true;
                        this.message = "Bite überprüfen Sie Ihre Eingaben. Eine Anmeldung ist nicht möglich";
                    }
                }
            }
        },
        mounted: function() {
            // Bei Aufruf dieser Seite werden
            this.$store.dispatch('clearBearer')
            this.$store.dispatch('setError', {
                state: false,
                message: {}
            })
        }
    }
</script>
<style scoped="true" lang="scss">
    .panel-login {

        width:70vw;
        max-width:400px;
        height:auto;
        position: fixed;

        top: 40%;
        left:50%;
        transform:translate(-50%,-40%);

        .login-mask {
            padding:2em 2em;
            label {
                color:#fff;
            }
        }
        box-shadow: 0px 0px 15px -2px rgba(0,0,0,0.75);
    }
</style>