import axios from 'axios'
import store from './state/index.js'

/** Aktion vor der Netzwerkanfrage */
axios.interceptors.request.use(function (config) {
  store.dispatch('setLoading', true)
  const token = store.state.bearer.access_token
  // Ermöglicht das Debuggen der Requests
  if (token) {
    config.headers.Authorization = `Bearer ${token}`
    config.headers.contentType = 'application/json'
  }
  // config.url += process.env.VUE_APP_PHPSTORM
  return config
})

/** Aktion nach der Netzwerkanfrage */
axios.interceptors.response.use(function (response) {
  store.dispatch('setLoading', false)
  return response
}, function (error) {
  store.dispatch('setLoading', false)
  return Promise.reject(error)
})

function buildURL (action) {
  return process.env.VUE_APP_ROOT_SIGNATUR_API + process.env[action]
}

const appService = {
  login (credentials) {
    const requestBody = new FormData()
    requestBody.append('username', credentials.username)
    requestBody.append('password', credentials.pwd)
    requestBody.append('grant_type', 'password')
    requestBody.append('scope', 'api')

    const options = {
      headers: { 'content-type': 'application/x-www-form-urlencoded', 'Authorization': 'Basic cm8uY2xpZW50OnNlY3JldA==' }
    }

    return new Promise((resolve, reject) => {
      axios.post(process.env.VUE_APP_AUTH_URL, requestBody, options)
        .then(response => {
          console.log(response)
          resolve(response.data)
        }).catch(response => {
          store.dispatch('setError', {
            state: true,
            message: response
          })
        })
    })
  },
  getVorgaenge () {
    return new Promise((resolve, reject) => {
      axios.get(buildURL('VUE_APP_VORGAENGE'))
        .then(response => {
          resolve(response)
        }).catch(response => {
          store.dispatch('setError', {
            state: true,
            message: response
          })
        })
    })
  },
  getLinks (vorgangsid) {
    return new Promise((resolve, reject) => {
      axios.get(buildURL('VUE_APP_LINK_URL') + vorgangsid)
        .then(response => {
          resolve(response)
        }).catch(response => {
          store.dispatch('setError', {
            state: true,
            message: response
          })
        })
    })
  },
  getVorgang (vorgangsid, randomURL) {
    return new Promise((resolve, reject) => {
      axios.get(buildURL('VUE_APP_VORGANG') + vorgangsid)
        .then(response => {
          resolve(response)
        }).catch(response => {
          store.dispatch('setError', {
            state: true,
            message: response
          })
        })
    })
  },
  loadDocumentFromURL (urlpart) {
    return new Promise((resolve, reject) => {
      axios.get(process.env.VUE_APP_ROOT_SIGNATUR_API + urlpart)
        .then(response => {
          resolve(response)
        }).catch(response => {
          store.dispatch('setError', {
            state: true,
            message: response
          })
        })
    })
  },
  loadSignaturePreview (vorgangsid, documentid, signatureid) {
    return new Promise((resolve, reject) => {
      axios.get(buildURL('VUE_APP_SIGNATURE_PREVIEW') + vorgangsid + '//' + documentid + '/' + signatureid)
        .then(response => {
          if (response.statusText === 'OK') {
            resolve(response.data)
          }
        }).catch(response => {
          store.dispatch('setError', {
            state: true,
            message: response
          })
        })
    })
  },
  addUserSignature (userSignature) {
    let config = {
      headers: {
        'Content-Type': 'application/json'
      }
    }
    return new Promise((resolve, reject) => {
      axios.post(buildURL('VUE_APP_SIGNATURE_ADD'), JSON.stringify(userSignature), config)
        .then(response => {
          if (response.statusText === 'OK') {
            resolve(response)
          } else {
            // Fehlermeldung ausgeben
          }
        }).catch(response => {
          store.dispatch('setError', {
            state: true,
            message: 'Die Unterschrift konnte nicht übermittelt werden. Bitte versuchen später die erneute Übermittlung.'
          })
        })
    })
  },
  getShortlink (shorttoken, versicherungsnummer) {
    return new Promise((resolve, reject) => {
      let config = {
        headers: {
          'Content-Type': 'application/json'
        }
      }
      axios.post(process.env.VUE_APP_SHORTLINK, JSON.stringify({ token: shorttoken, versnr: versicherungsnummer }), config)
        .then(response => {
          if (response.statusText === 'OK') {
            resolve(response.data)
          } else {
            store.dispatch('setError', {
              state: true,
              message: response
            })
          }
        }).catch(response => {
          store.dispatch('setError', {
            state: true,
            message: response
          })
        })
    })
  },
  sendDokument (dokument) {
    return new Promise((resolve, reject) => {
      let config = {
        headers: {
          'Content-Type': 'application/json'
        }
      }
      axios.post(process.env.VUE_APP_SEND, JSON.stringify(dokument), config)
        .then(response => {
          if (response.statusText === 'OK') {
            resolve(response.data)
          } else {
            store.dispatch('setError', {
              state: true,
              message: response
            })
          }
        })
    })
  }
}

export default appService
