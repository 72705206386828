<template>
    <div class="splash-container">
        <div class="panel panel-default splash-panel" v-bind:style="{backgroundColor: panelBackgroundNeutral}" >
            <div class="panel-body text-center">
                <h1>IDEAL Signatur</h1>
                <p>Ermöglicht Unterschriften online!</p>

                <div class="" v-if="!isAnon">
                    <router-link tag="button" to="/token" class="btn btn-primary " v-if="!authentificated" v-bind:style="[primaryCTAColor]">Ich habe einen Code</router-link>
                </div>
                <div v-if="isAnon">
                    <p>Bitte warten. Ihr Dokument wird vorbereitet...</p>
                </div>
            </div>
            <router-link
                tag="a" to="/login"
                class="go-to-login"
                v-if="!authentificated && !isAnon"
                v-bind:style="{color:labelColorLight}"
                >Zum Login für Vertriebspartner</router-link>
        </div>
    </div>
</template>
<script>
import { mapGetters } from 'vuex'
import { theme } from '../mixin/theme.js'

export default {
  mixins: [theme],
  props: ['id', 's', 'rolle'],
  computed: {
    ...mapGetters(['authentificated'])
  },
  data: function () {
    return {
      isAnon: true
    }
  },
  mounted: async function () {
    /**
    * Wird nur verwendet für den Aufruf als einzelner Unterzeichner
     *
     */

    if (typeof this.id !== 'undefined' && typeof this.s !== 'undefined') {
      // Lade Dokument
      this.$store.dispatch('setPublicCredentials', {
        id: this.id,
        s: encodeURIComponent(this.s),
        rolle: this.rolle
      })

      await this.$store.dispatch('getPublicDocument')
      await this.$store.dispatch('setMode', 'anonymous')
      this.$router.push({ name: 'unterzeichner' })
    } else {
      this.isAnon = false
    }
  }
}
</script>
<style scoped="true" lang="scss">
    .splash-panel {
        width:90vw;
        max-width:500px;
        height:auto;
        position: absolute;
        left:5vw;
        top:40%;
        left:50%;
        padding:2em;
        transform: translate(-50%, -40%);
    }

    .go-to-login {
        position: absolute;
        bottom:-80px;
        text-decoration: underline;
        transform: translate(-50%, -40%);
        left:50%;
        text-align:center;
    }
</style>
