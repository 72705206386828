import Vue from 'vue'
import Vuex from 'vuex'

import { signature } from './modules/signature'
import { theme } from './modules/theme'
import appService from './../app.service.js'
import createPersistedState from 'vuex-persistedstate'

// import mockdata from './test.json';

Vue.use(Vuex)

const store = new Vuex.Store({
  plugins: [createPersistedState({ storage: window.sessionStorage })],
  state: {
    bearer: false,
    isAuthentificated: false,
    vorgaenge: null,
    vorgang: null,
    pdfPublicURL: null,
    pcredentials: null,
    randomizeURL: Date.now(), // Verhindert das Cachen von PDFS, wenn diese neugeladen werden müssen
    mode: 'app', // Legt das Verhalten der App fest. Anderer Modus ist Underwriter, Servicecenter
    loading: false,
    documentLoad: null,
    menu: true,
    error: false,
    message: '',
    componentloading: false,
    helpopen: false
  },
  modules: {
    signature: signature,
    theme: theme
  },
  getters: {
    token: (state) => {
      return state.bearer.access_token
    },
    randomizeURL: (state) => {
      return state.randomizeURL
    },
    url: (state) => {
      return state.documents
    },
    vorgang: (state) => {
      return state.vorgang
    },
    pcredentials: (state) => {
      return state.pcredentials
    },
    role: (state) => {
      if (state.pcredentials.rolle !== undefined) {
        return state.pcredentials.rolle
      }
      return ''
    },
    mode: (state) => {
      return state.mode
    },
    loading: (state) => {
      return state.loading
    },
    authentificated: (state) => {
      return state.isAuthentificated
    },
    menu: (state) => {
      return state.menu
    },
    error: (state) => {
      return state.error
    },
    message: (state) => {
      return state.message
    },
    componentloading: (state) => {
      return state.componentloading
    },
    helpopen: (state) => {
      return state.helpopen
    },
    signatureChain: (state) => {
      /**
       * Erzeugt eine verkette Liste mit jeweils dem Verweis auf die vorherige und folgende Signatur
       * @type {Array}
       */

      let signaturelist = []
      let docs = state.vorgang.dokumente
      let prevdoc = null
      let prevpage = null

      for (let docid in docs) {
        for (let signkey in docs[docid]['unterschriftenfelder']) {
          let signaturepage = docs[docid]['unterschriftenfelder'][signkey]['seite']

          if (signaturepage !== prevpage) {
            signaturelist.push({
              docid: docid,
              page: signaturepage,
              prevDocid: prevdoc,
              prevPage: prevpage,
              nextDocid: null,
              nextPage: null
            })
          }
          prevpage = signaturepage
        }
        prevdoc = docid
      }

      for (let i = 0, j = signaturelist.length; i < j; i++) {
        if (typeof signaturelist[i + 1] !== 'undefined') {
          signaturelist[i]['nextDocid'] = signaturelist[i + 1].docid
          signaturelist[i]['nextPage'] = signaturelist[i + 1].page
        } else {
          signaturelist[i]['nextDocid'] = signaturelist[0].docid
          signaturelist[i]['nextPage'] = signaturelist[0].page
        }
      }

      return signaturelist
    }
  },

  actions: {
    clearBearer: (context, authstate) => {
      context.commit('clearBearer')
      context.commit('setAuthentificated', false)
      store.dispatch('signature/clear')
    },
    randomizeURL: (context) => {
      context.commit('randomizeURL')
    },
    setAuthentificated: (context, authstate) => {
      context.commit('setAuthentificated', authstate)
    },
    setMode: (context, mode) => {
      context.commit('setMode', mode)
    },
    login: (context, credentials) => {
      return new Promise((resolve, reject) => {
        appService.login({ username: credentials.username, pwd: credentials.password }).then((data) => {
          var authObj = data
          if (authObj.hasOwnProperty('error')) {
            reject(authObj.error)
          } else {
            context.commit('login', authObj)
            resolve()
          }
        })
      })
    },
    logout: (context) => {
      context.dispatch('clearBearer', false)
    },
    getVorgaenge: (context) => {
      return new Promise((resolve) => {
        appService.getVorgaenge().then((data) => {
          context.commit('addVorgaenge', data.data)
          resolve(context.state.vorgaenge)
        })
      })
    },
    getLinks: (context, vorgangsid) => {
      return new Promise((resolve) => {
        appService.getLinks(vorgangsid).then((data) => {
          resolve(data.data)
        })
      })
    },
    getVorgang: (context, param) => {
      return new Promise((resolve) => {
        appService.getVorgang(param, context.state.randomizeURL).then((data) => {
          context.commit('addVorgang', data.data)
          // store.dispatch('signature/setSignatures', mockdata)
          store.dispatch('signature/setSignatures', data.data)
          resolve()
        })
      })
    },
    getPublicDocument: (context) => {
      return new Promise((resolve) => {
        const role = context.state.pcredentials.rolle ? '&rolle=' + context.state.pcredentials.rolle : ''

        appService.loadDocumentFromURL('signatur/vorgang/anonymous/' + context.state.pcredentials.id + '?s=' + context.state.pcredentials.s + role + '&_=' + context.state.randomizeURL)

          .then((response) => {
            if (response.data) {
              context.commit('addVorgang', response.data)
              store.dispatch('signature/setSignatures', response.data)
              resolve()
            }
          })
      })
    },
    setPublicCredentials: (context, credentials) => {
      context.commit('pCrenditials', credentials)
    },
    setLoading: (context, loadingstate) => {
      context.commit('setLoading', loadingstate)
      return true
    },
    setMenuVisibility: (context, menustate) => {
      context.commit('setMenuVisibility', menustate)
    },
    setError: (context, error) => {
      context.commit('setError', error.state)
      context.commit('setMessage', error.message)
    },
    setComponentLoading: (context, loadingstate) => {
      context.commit('setComponentLoading', loadingstate)
    },
    toggleHelpOpen: (context) => {
      context.commit('toggleHelpOpen', !context.state.helpopen)
    }
  },

  mutations: {
    login: (state, authObj) => {
      state.bearer = authObj
      state.isAuthentificated = true
    },
    addVorgaenge: (state, data) => {
      state.vorgaenge = data
    },
    addVorgang: (state, data) => {
      state.vorgang = data
    },
    pCrenditials: (state, credentials) => {
      state.pcredentials = credentials
    },
    randomizeURL: (state) => {
      state.randomizeURL = Date.now()
    },
    setMode: (state, mode) => {
      state.mode = mode
    },
    setLoading: (state, loadingstate) => {
      state.loading = loadingstate
    },
    setMenuVisibility: (state, menustate) => {
      state.menu = menustate
    },
    setError: (state, errorstate) => {
      state.error = errorstate
    },
    setMessage: (state, message) => {
      state.message = message.response
    },
    setAuthentificated: (state, authstate) => {
      state.isAuthentificated = authstate
    },
    clearBearer: (state) => {
      state.bearer = false
      state.isAuthentificated = false
      state.pcredentials = null
      state.signature.signatures = null
      state.vorgang = null
      state.vorgaenge = null
      state.mode = 'app'
    },
    setComponentLoading: (state, loadingstate) => {
      state.componentloading = loadingstate
    },
    toggleHelpOpen: (state, openstate) => {
      state.helpopen = openstate
    }
  }
})

export default store
