import 'babel-polyfill'
import 'url-polyfill'

import Vue from 'vue'
import App from './App.vue'
import VueKonva from 'vue-konva'
import vueSmoothScroll from 'vue-smoothscroll'

import { library } from '@fortawesome/fontawesome-svg-core'
import { faInfoCircle } from '@fortawesome/free-solid-svg-icons'
import { faMinusCircle } from '@fortawesome/free-solid-svg-icons'
import { faPlusCircle } from '@fortawesome/free-solid-svg-icons'
import { faChevronLeft } from '@fortawesome/free-solid-svg-icons'
import { faChevronRight } from '@fortawesome/free-solid-svg-icons'
import { faEnvelope } from '@fortawesome/free-solid-svg-icons'
import { faCheck } from '@fortawesome/free-solid-svg-icons'
import { faImages } from '@fortawesome/free-solid-svg-icons'
import { faFileSignature } from '@fortawesome/free-solid-svg-icons'
import { faPlus } from '@fortawesome/free-solid-svg-icons'
import { faMinus } from '@fortawesome/free-solid-svg-icons'
import { faCompressArrowsAlt } from '@fortawesome/free-solid-svg-icons'
import { faCog } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'

import router from './router'
import store from './state/index.js'

import Ionic from '@ionic/vue'

library.add(faInfoCircle)
library.add(faMinusCircle)
library.add(faPlusCircle)
library.add(faMinusCircle)
library.add(faChevronLeft)
library.add(faChevronRight)
library.add(faEnvelope)
library.add(faCheck)
library.add(faCompressArrowsAlt)
library.add(faImages)
library.add(faFileSignature)
library.add(faPlus)
library.add(faMinus)
library.add(faCog)

Vue.config.productionTip = false

Vue.use(VueKonva)
Vue.use(vueSmoothScroll)
Vue.component('font-awesome-icon', FontAwesomeIcon)
Vue.use(Ionic)

new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')

// Apple IOS only
/**
document.addEventListener('touchmove', function (event) {
  if (event.scale !== 1) { event.preventDefault() }
}, { passive: false })
**/
