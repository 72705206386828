<template>
  <div id="app" v-bind:style="{'background-color':bodyColor}">
    <navigation></navigation>
    <loading v-if="loading"></loading>
    <error v-if="error"></error>
    <router-view/>
    <hotline></hotline>
  </div>
</template>

<script>
import navigation from './components/navigation.vue'
import loading from './components/loading.vue'
import hotline from './components/hotline.vue'
import error from './components/error.vue'
import { mapGetters } from 'vuex'

import { theme } from './mixin/theme.js'

export default {
  mixins: [theme],
  components: {
    'hotline': hotline,
    'loading': loading,
    'navigation': navigation,
    'error': error
  },
  computed: {
    ...mapGetters(['loading', 'error'])
  }
}
</script>

<style lang="scss">
  @import '../node_modules/bootstrap/scss/bootstrap.scss';
  @import './assets/styles/style.scss';
</style>
