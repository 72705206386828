import Vue from 'vue'
import Router from 'vue-router'
import store from './state/index.js'

import splash from '@/views/splash.vue'
import login from '@/views/login.vue'

Vue.use(Router)

function loadView (view) {
  return () => {
    store.dispatch('setLoading', true)
    return import(`@/views/${view}.vue`)
  }
}

const routes = [
  { path: '/', name: 'splash', component: splash, props: (route) => { return route.query || {} } },
  { path: '/login', name: 'login', component: login },
  { path: '/vorgaenge', name: 'vorgaenge', component: loadView('vorgaenge'), meta: { requiresAuth: true } },
  { path: '/dokument/:id', name: 'dokument', component: loadView('pdfviewer'), meta: { requiresAuth: true } },
  { path: '/dokument', name: 'dokument_blank', component: loadView('error') },
  { path: '/document/unterzeichner', name: 'unterzeichner', component: loadView('pdfviewer') },
  { path: '/token/', name: 'token', component: loadView('token') },
  { path: '/token/:token/:versnr', name: 'token', component: loadView('token') }
]

const router = new Router({
  routes: routes
  // mode: 'history' // Schönere URL Darstellung, funktioniert mit IPOS jedoch nicht wie erwartet
})

router.beforeEach((to, from, next) => {
  if (to.matched.some(record => record.meta.requiresAuth)) {
    if (!store.getters['authentificated']) {
      next({ name: 'login', query: { 'to': to.name } })
    } else {
      next()
    }
  } else {
    next()
  }
})

export default router
